import React from 'react'
import { useIntl } from 'react-intl'
import { withStyles, Box, Typography, Hidden } from '@material-ui/core'
import PublishOutlinedIcon from '@material-ui/icons/PublishOutlined'

import { feedback } from '../../../../../core/feedback'
import { ExpansionPanel, FileUploader, useModalContext } from '../../../../../ui'
import { ReactComponent as BulletinIcon } from '../../../../../resources/images/icon_bulletin.svg'
import { APPLICATION_FULL_PATHS } from '../../../constants'
import {
  BULLETIN_STEP_COMPLETED_STATES,
  BULLETIN_STEP_UPLOAD_PERMITTED_STATES,
  BULLETIN_STEP_UNCANCELLABLE_STATES,
  MUST_ANSWER_CANCEL_REQUEST,
  CAN_SELECT_INSTALLER, UPLOAD_TALLO_PERMITTED_STATES, UPLOAD_TALLO_PERMITTED_MARKETS,
} from './constants'
import { BULLETIN_FILE_SIZE } from './constants'

import { useBulletinStep } from './bulletin-step.hook'
import { BulletinEventsList } from './bulletin-events-list.component'
import { CancelBtn, GetInstallerBtn, SetInstaller } from '../../buttons'
import { bulletinStepStyles } from './bulletin-step.styles'
import { EditCertificateDataForm } from '../../dialogs/add-certificateData-form'

export const BulletinStepComponent = withStyles(bulletinStepStyles)(({ classes, model }) => {
  const { formatMessage } = useIntl()
  const { downloadBulletinFile, uploadBulletinFile } = useBulletinStep()

  const canChangeInstaller =
    model['camino_solicitud'] === '01' ||
    (model['camino_solicitud'] === '02' &&
      (CAN_SELECT_INSTALLER[model.estado] ||
        (model?.estado === '37' && model.certificado_IRG3?.id === null)) &&
      (model['d_IRG3'] === null || model['certificado_IRG3_id'] === null))

  const haveInstaller = model['empresa_ca'] || model['empresa_ca_id']
  const isIndoorInstallation = !APPLICATION_FULL_PATHS[model['camino_solicitud']]
  const isCancellationPermitted = !BULLETIN_STEP_UNCANCELLABLE_STATES[model['estado']]
  const budget = APPLICATION_FULL_PATHS[model['camino_solicitud']]
    ? model['presupuesto_exterior']
    : model['presupuesto_interior']
  const isUploadPermitted = BULLETIN_STEP_UPLOAD_PERMITTED_STATES[model['estado']]
  const isFotoTalloUploadPermitted = UPLOAD_TALLO_PERMITTED_STATES[model['estado']]
  const mustAnswerCancelRequest = MUST_ANSWER_CANCEL_REQUEST[model['estado']]
  const {
    actions: { open },
  } = useModalContext()

  /* const isRiteUploadPermitted = !BULLETIN_STEP_UPLOAD_RITE_NOT_PERMITTED_STATES[model['estado']]
   const showRiteUploadButton = model['calefaccion'] && isRiteUploadPermitted
   const showRiteMessage = model['calefaccion'] && !model['d_CC'] && isRiteUploadPermitted*/
  const paymentNeeded =
    APPLICATION_FULL_PATHS[model['camino_solicitud']] &&
    budget['distancia_real'] > budget['distancia_asumida_nedgia']
  const alreadyCompleted = BULLETIN_STEP_COMPLETED_STATES[model['estado']]
  // const getBulletinState = () => {
  //   if (alreadyCompleted) return 'validated'
  //   else if (model['estado'] === APPLICATION_STATES.CERTIFICATE_REJECTED.KEY) return 'rejected'
  //   else if (model['estado'] === APPLICATION_STATES.CERTIFICATE_ATTACHED.KEY) return 'uploaded'
  //   else return 'pending'
  // }
  const handleUploadFile = (data, documentIdentification) => {
    console.log(model)
    if ((model.tipo_solicitud === 'Cliente' || model.tipo_solicitud === 'Gestor') && model.campana !== null) {
      handleOpenModal(data, documentIdentification)
    } else {
      uploadBulletinFile(model, data, documentIdentification)
        .then(() => {
          handleSuccessfulOperation('Documento subido')
        })
        .catch(() => {
          handleErrors('Error al subir el documento')
        })
    }
  }

  const handleOpenModal = (data, documentIdentification) => {
    open({
      Component: EditCertificateDataForm,
      data: {
        model,
        documentIdentification,
        uploadBulletinFile,
        data,
        handleSuccessfulOperation,
        handleErrors,
      },
      maxWidth: 'md',
      type: 'responsive',
      closable: true,
    })
  }

  const handleDownloadFile = (fileId) => downloadBulletinFile(fileId)
  const handleUploadError = (data) => feedback('error', data.mssg)
  const handleSuccessfulOperation = (message) => feedback('success', message)
  const handleErrors = (message) => feedback('error', message)
  // const handleGoInstallationConfig = () => window.open(INST_CONFIG_URL(model))

  const uploadCertificateButton = (
    isUploadPermitted && (
      <Box>
        <FileUploader
          maxSize={BULLETIN_FILE_SIZE}
          applicationId={model['id']}
          label={formatMessage({
            id: 'pages.gasApplications.wizard.bulletin.subir_certificado.label',
          })}
          icon={<PublishOutlinedIcon />}

          size="large"
          extra={formatMessage(
            {
              id: 'pages.gasApplications.wizard.bulletin.uploadMessage',
            },
            { size: parseInt(BULLETIN_FILE_SIZE / 1000000) }
          )}
          color="primary"
          type="IR"
          accept={['application/pdf']}
          onSuccess={(data) => handleUploadFile(data, 'd_IRG3_id')}
          onError={(data) => handleUploadError(data)}
          style={{ display: 'flex', justifyContent: 'center', minWidth: '200px' }}
        />
      </Box>
    )
)

  const uploadFotoTalloButton = (
    isFotoTalloUploadPermitted && (
      <Box>
        <FileUploader
          maxSize={BULLETIN_FILE_SIZE}
          applicationId={model['id']}
          label={formatMessage({
            id: 'pages.gasApplications.wizard.bulletin.subir_tallo.label',
          })}
          size="large"
          icon={<PublishOutlinedIcon />}
          extra={formatMessage(
            {
              id: 'pages.gasApplications.wizard.bulletin.uploadMessage',
            },
            { size: parseInt(BULLETIN_FILE_SIZE / 1000000) }
          )}
          color="primary"
          type="tallo_i"
          accept={['application/pdf']}
          onSuccess={(data) => handleUploadFile(data, 'd_tallo_i_id')}
          onError={(data) => handleUploadError(data)}
          style={{ display: 'flex', justifyContent: 'center' }}
        />
      </Box>
    )
  )

  return (
    <ExpansionPanel
      isExpanded={!alreadyCompleted}
      triggerPosition="right"
      summary={
        <Box mb={1}>
          <Box display="flex" alignItems="center" mb={1}>
            <Hidden xsDown>
              <BulletinIcon />
            </Hidden>
            <Typography variant="h5">
              {model['camino_solicitud'] === '01' ? formatMessage({ id: 'pages.gasApplications.wizard.bulletin.exterior.title' }) : formatMessage({ id: 'pages.gasApplications.wizard.bulletin.interior.title' })}
            </Typography>
          </Box>
          <Typography variant="body2" paragraph>
            {UPLOAD_TALLO_PERMITTED_MARKETS.includes(model['mercado']) ? formatMessage({
                id: 'pages.gasApplications.wizard.bulletin.description.2',
              }) :
            formatMessage({ id: 'pages.gasApplications.wizard.bulletin.description.1' })}
            {model['mercado'] !== 'SV' && model['camino_solicitud'] === '01'
              ? paymentNeeded
                ?
                  formatMessage({
                      id: 'pages.gasApplications.wizard.bulletin.description.1.1',
                    })
                : formatMessage({
                    id: 'pages.gasApplications.wizard.bulletin.description.1.2',
                  })
              : ''}
          </Typography>
          {/* {model['mercado'] !== 'SV' && (
            <Box>
              <Typography variant="body2">
                {formatMessage({ id: 'pages.gasApplications.wizard.bulletin.description.3' })}
              </Typography>
              <Typography variant="body2" paragraph>
                {formatMessage({ id: 'pages.gasApplications.wizard.bulletin.description.4.1' })}
                <span onClick={handleGoInstallationConfig} className={classes.urlLink}>
                  {formatMessage({ id: 'pages.gasApplications.wizard.bulletin.description.4.2' })}
                </span>
                {formatMessage({ id: 'pages.gasApplications.wizard.bulletin.description.4.3' })}
              </Typography>
            </Box>
          )} */}
          <Box display="flex" alignItems="center" justifyContent="space-between" flexWrap="wrap">
            <Box display="flex">
              <Typography variant="body2" className={classes.marginRight}>
                {formatMessage({ id: 'pages.gasApplications.wizard.bulletin.doc.state.label' })}:
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {/* {formatMessage({
                  id: `pages.gasApplications.wizard.bulletin.doc.state.${getBulletinState()}`,
                })} */}
                {model['certificado_IRG3']['estado'] ||
                  formatMessage({
                    id: `pages.gasApplications.wizard.bulletin.doc.state.pending`,
                  })}
              </Typography>
            </Box>
            <Box display="flex">
              <Typography variant="body2" className={classes.marginRight}>
                {formatMessage({ id: 'pages.gasApplications.wizard.bulletin.cups.label' })}:
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {model['cups'] || '--'}
              </Typography>
            </Box>
            <Box display="flex">
              <Typography variant="body2" className={classes.marginRight}>
                {formatMessage({
                  id: 'pages.gasApplications.wizard.bulletin.cups.state.label',
                })}
                :
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {model['estado_cups'] || '--'}
              </Typography>
            </Box>
          </Box>
        </Box>
      }
      details={<BulletinEventsList model={model} certificate_button={uploadCertificateButton} tallo_button ={uploadFotoTalloButton} />}
      //TODO: Make a component for download link + title + name of the document (when the flow becomes clear)
      footer={
        <>
          <Box display="flex" alignItems="center" flexWrap="wrap">
            {model['certificado_IRG3']['d_IRG3'] && (
              <Box flexGrow={1}>
                <Typography variant="subtitle2">
                  {formatMessage({
                    id: 'pages.gasApplications.wizard.bulletin.certificate.label',
                  })}
                  :
                </Typography>
                <Typography variant="subtitle2">
                  {model['certificado_IRG3']['d_IRG3']['nombre']}
                </Typography>
                <span onClick={() => handleDownloadFile(model['certificado_IRG3']['d_IRG3']['id'])}>
                  <Typography variant="body2" className={classes.downloadLink}>
                    {formatMessage({
                      id: 'pages.gasApplications.wizard.bulletin.download.label',
                    })}
                  </Typography>
                </span>
              </Box>
            )}
            {isIndoorInstallation && model['d_RC'] ? (
              <Box flexGrow={1}>
                <Typography variant="subtitle2">
                  {formatMessage({
                    id: 'pages.gasApplications.wizard.bulletin.summary.label',
                  })}
                  :
                </Typography>
                <Typography variant="subtitle2">{model['d_RC']['nombre']}</Typography>
                <span onClick={() => handleDownloadFile(model['d_RC']['id'])}>
                  <Typography variant="body2" className={classes.downloadLink}>
                    {formatMessage({
                      id: 'pages.gasApplications.wizard.bulletin.download.label',
                    })}
                  </Typography>
                </span>
              </Box>
            ) : null}
            {isIndoorInstallation && model['d_CCEE'] ? (
              <Box flexGrow={1}>
                <Typography variant="subtitle2">
                  {formatMessage({
                    id: 'pages.gasApplications.wizard.bulletin.ccee.label',
                  })}
                  :
                </Typography>
                <Typography variant="subtitle2">{model['d_CCEE']['nombre']}</Typography>
                <span onClick={() => handleDownloadFile(model['d_CCEE']['id'])}>
                  <Typography variant="body2" className={classes.downloadLink}>
                    {formatMessage({
                      id: 'pages.gasApplications.wizard.bulletin.download.label',
                    })}
                  </Typography>
                </span>
              </Box>
            ) : null}
            {model['d_CC'] && (
              <Box flexGrow={1}>
                <Typography variant="subtitle2">
                  {formatMessage({
                    id: 'pages.gasApplications.wizard.bulletin.rite.label',
                  })}
                  :
                </Typography>
                <Typography variant="subtitle2">{model['d_CC']['nombre']}</Typography>
                <span onClick={() => handleDownloadFile(model['d_CC']['id'])}>
                  <Typography variant="body2" className={classes.downloadLink}>
                    {formatMessage({
                      id: 'pages.gasApplications.wizard.bulletin.download.label',
                    })}
                  </Typography>
                </span>
              </Box>
            )}
            <Box display="flex" flexGrow={1} justifyContent="flex-end" alignItems="center">

              {/*{showRiteUploadButton && (
                <Box ml={3}>
                  <FileUploader
                    maxSize={BULLETIN_FILE_SIZE}
                    applicationId={model['id']}
                    label={formatMessage({
                      id: 'pages.gasApplications.wizard.bulletin.button.rite.label',
                    })}
                    icon={<PublishOutlinedIcon />}
                    extra={formatMessage(
                      {
                        id: 'pages.gasApplications.wizard.bulletin.rite.uploadMessage',
                      },
                      { size: BULLETIN_FILE_SIZE / 1000000 }
                    )}
                    color="primary"
                    type="CC"
                    accept={['application/pdf']}
                    onSuccess={(data) => handleUploadFile(data, 'd_CC_id')}
                    onError={(data) => handleUploadError(data)}
                    style={{ display: 'flex', justifyContent: 'center' }}
                  />
                </Box>
              )}*/}
              <Box display="flex" alignItems="center">
                {canChangeInstaller ? (
                  !haveInstaller ? (
                    <Box display="flex" height="100%" alignItems="center" flexGrow={1} ml={1.5}>
                      <GetInstallerBtn {...{ model }} />
                    </Box>
                  ) : (
                    <Box display="flex" height="100%" alignItems="center" flexGrow={1} ml={1.5}>
                      <SetInstaller {...{ model }} />
                    </Box>
                  )
                ) : null}
                {isCancellationPermitted && !mustAnswerCancelRequest ? (
                  <Box ml={1.5}>
                    <CancelBtn {...{ model }} />
                  </Box>
                ) : null}
              </Box>
            </Box>
            {/*{showRiteMessage && (
              <InfoBox
                severity="warning"
                message={formatMessage({
                  id: 'pages.gasApplications.wizard.bulletin.rite.message',
                })}
              />
            )}*/}
          </Box>
        </>
      }
    />
  )
})
