import { APPLICATION_STATES } from '../../../constants'

export const BULLETIN_STEP_COMPLETED_STATES = {
  [APPLICATION_STATES.CERTIFICATE_PROCESSED.KEY]: true,
  [APPLICATION_STATES.FINISHED.KEY]: true,
}

export const BULLETIN_STEP_UPLOAD_PERMITTED_STATES = {
  [APPLICATION_STATES.ORDER_PENDING.KEY]: true,
  [APPLICATION_STATES.BUDGET_PAID.KEY]: true,
  [APPLICATION_STATES.CERTIFICATE_ATTACHED.KEY]: true,
  [APPLICATION_STATES.CERTIFICATE_REJECTED.KEY]: true,
}

export const UPLOAD_TALLO_PERMITTED_STATES = {
  [APPLICATION_STATES.CERTIFICATE_PROCESSED.KEY]: true,
  [APPLICATION_STATES.ORDER_PENDING.KEY]: true,
  [APPLICATION_STATES.BUDGET_PAID.KEY]: true,
  [APPLICATION_STATES.CERTIFICATE_ATTACHED.KEY]: true,
  [APPLICATION_STATES.CERTIFICATE_REJECTED.KEY]: true,

}
export const BULLETIN_STEP_UPLOAD_RITE_NOT_PERMITTED_STATES = {
  [APPLICATION_STATES.REJECTED.KEY]: true,
  [APPLICATION_STATES.REJECTED_2.KEY]: true,
  [APPLICATION_STATES.FINISHED.KEY]: true,
}
export const MUST_ANSWER_CANCEL_REQUEST = {
  [APPLICATION_STATES.CLIENT_CANCEL_PENDING.KEY]: true,
}

export const UPLOAD_TALLO_PERMITTED_MARKETS = [
  'SP',
  'SH',
]

export const CAN_SELECT_INSTALLER = {
  [APPLICATION_STATES.CREATED.KEY]: true,
  [APPLICATION_STATES.REVIEW.KEY]: true,
  [APPLICATION_STATES.NOT_ASSIGNED.KEY]: true,
  [APPLICATION_STATES.VALIDATION_PENDING.KEY]: true,
  [APPLICATION_STATES.ASSIGNED.KEY]: true,
  [APPLICATION_STATES.BUDGET_SENDED.KEY]: true,
  [APPLICATION_STATES.BUDGET_ACCEPTED.KEY]: true,
  [APPLICATION_STATES.PAYMENT_VALIDATION.KEY]: true,
  [APPLICATION_STATES.BUDGET_PAID.KEY]: true,
}

export const BULLETIN_STEP_UNCANCELLABLE_STATES = {
  [APPLICATION_STATES.FINISHED.KEY]: true,
  [APPLICATION_STATES.REJECTED_SURVEY.KEY]: true,
  [APPLICATION_STATES.REJECTED.KEY]: true,
  [APPLICATION_STATES.REJECTED_2.KEY]: true,
  [APPLICATION_STATES.REJECTED_NORMALIZATION]: true,
}

export const BULLETIN_LIST_HEADER = [
  {
    key: 'dg_ts_insert',
    label: 'Fecha',
    type: 'datetime',
  },
  {
    key: 'mensaje',
    label: 'Estado',
  },
  {
    key: 'action',
    label: '',
  }
]

export const BULLETIN_FILE_SIZE =2 * 1024 * 1024

export const INST_CONFIG_URL = (data) => {
  const userQueryParams = [
    'anexo',
    'bloque',
    'cidi',
    'cod_postal',
    'direccion',
    'escalera',
    'municipio',
    'numero',
    'piso',
    'puerta',
    'tipo_calle',
  ]
    .map((name) => name + '=' + data[name])
    .join('&')

  return `${process.env.REACT_APP_WP_URL}/configuracion-vivienda?${userQueryParams}`
}
